import { HeadingMd, HeadingXl } from '/features/buildingBlocks/Heading'
import { SectionSand, SectionWhite } from '/features/pageOnly/Section'
import { VideoWithContext } from '/features/buildingBlocks/Video'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { useMediaQuery } from '@kaliber/use-media-query'
import { PortableText } from '@portabletext/react'

import mediaStyles from '/cssGlobal/media.css'
import styles from './VideoGroupRepeater.css'

export function VideoGroupRepeater({
  videoGroups,
  EvenComponent = SectionSand,
  OddComponent = SectionWhite
}) {
  return (
    <>
      {videoGroups?.map((group, i) => {
        const Section = i % 2
          ? OddComponent
          : EvenComponent

        return (
          <Section key={i} dataX='video-repeater' padding='lg'>
            <ContainerXl>
              <VideoGroup {...{ group }} />
            </ContainerXl>
          </Section>
        )
      })}
    </>
  )
}

function VideoGroup({ group }) {
  const isMobile = useMediaQuery(mediaStyles.viewportMd) === false

  const Heading = isMobile
    ? HeadingMd
    : HeadingXl

  return (
    <div className={styles.componentVideoGroup}>
      <div className={styles.textContentBlock}>
        <Heading h='2' title={group?.title} />
        <PortableText value={group?.content} />
      </div>

      <div className={styles.videoRepeaterGrid}>
        {group.videoItems?.map((v, i) => (
          <VideoWithContext
            key={i}
            url={v.video?.url}
            trackingTitle={v.video?.title}
            title={v.sequenceTitle}
            description={v.videoTitle}
            poster={v.video?.poster}
            posterSizes={`
              (min-width: ${mediaStyles.breakpointLg}) calc(var(--container-max-width-xl) * 0.75),
              calc(100vw - var(--container-padding))
            `}
          />
        ))}
      </div>
    </div>
  )
}
